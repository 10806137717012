.input{
    width: 100%;
    padding: 1.2rem;
    border-radius: 0.5rem;
    background-color: #F1F2F6;
    border: none;
}

.input::placeholder{
    font-size: 1rem;
    color: rgb(0, 0, 0);
}

.input-select{
    font-size: 1rem;
    color: rgb(0, 0, 0);
}