:root {
  --main-font: "Montserrat", sans-serif;
  --reey-font: "reeyregular";
  --base-purple: #2a0e59;
  --primary: #ffd45f;
  --gray: #757783;
  --link-gray: #aeb2c2;
  --light-gray: #626368;
  --theme-black: #1c1e27;
  --theme-black-rgb: 28, 30, 39;
  --brand-yellow: #ffba1b;
  --brand-blue: #0b44b3;
  --red: #fc0200;
  --blue: #28abfd;
  --body-side-paddings: 11rem;
}

body{
  background-color: #fff !important;
  color: #000 !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--main-font);
}

a {
  text-decoration: none;
  color: #fff;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-02 {
  margin-top: 0.2rem;
}

.mt-07 {
  margin-top: 0.7rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-105 {
  margin-top: 1.5rem;
}

.m-03 {
  margin: 0 0.5rem;
}

.mr-1 {
  margin-right: 2rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-05 {
  margin-left: 0.5rem;
}

.m-05 {
  margin: 0 0.5rem;
}

.relative {
  position: relative;
}

.color--brandblue {
  color: var(--brand-blue);
}

.color--brandyellow {
  color: var(--brand-yellow);
}

.color--blue {
  color: var(--blue);
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgb(var(--theme-black-rgb), 0.7);
}

.overlay--dark {
  background-color: rgb(var(--theme-black-rgb), 0.95);
}

.navbar--active {
  position: fixed;
  top: 0;
  height: 90px;
  z-index: 9999;
  background-color: #fff;
  transition: all ease-in 0.2s;
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.05);
}

.navbar--active a {
  color: #000 !important;
}

.navbar--active .menu-icon {
  color: #000 !important;
}

.navbar--active .nav-icon-btn {
  color: #000 !important;
}

.navbar--active .nav-submenu-link:hover {
  color: #fff;
}

.colored-nav a {
  color: #000 !important;
}

.colored-nav .menu-icon {
  color: #000 !important;
}

.colored-nav .nav-icon-btn {
  color: #000 !important;
}

.border-round {
  border-radius: 0.5rem;
}

.round{
  border-radius: 50%;
  object-fit: cover;
}

.menu-icon {
  display: none;
}

.bgcolor--blue{
  background-color: var(--brand-blue);
}

.bgcolor--yellow{
  background-color: var(--brand-yellow);
}

.bgcolor--purple{
  background-color: var(--base-purple);
}

.center{
  text-align: center;
}

.validation-message{
  color: var(--red);
  font-size: 0.8rem;
  display: inline-block;
  margin: 0.5rem;
}

.loading-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}

.loading-overlay.overlay-active {
  display: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}