.lancet {
  width: 100%;
}

.lancet__navbar {
  padding: 1rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lancet__navbar-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lancet__navbar-login > h2 {
  font-weight: 500;
  font-size: 1.3rem;
  margin-right: 2rem;
}

.lancet-infobar {
  background-color: var(--brand-yellow);
  text-align: center;
  padding: 0.5rem;
  font-weight: 500;
}

.lancet__hero {
  display: flex;
  height: 60vh;
}

.lancet__hero-img {
  width: 50%;
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1629218574/website_images/Scholarship-hero.jpg");
  background-size: cover;
  background-position: center;
}

.lancet__details {
  width: 50%;
  padding: 2rem;
  padding-right: var(--body-side-paddings);
  background-color: var(--base-purple);
}

.lancet__heading {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
}

.lancet__timings {
  font-size: 0.8rem;
  color: #fff;
  margin-top: 1rem;
}

.lancet__money {
  width: 83%;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0.5rem;
}

.lancet__money > h2 {
  width: 90%;
  padding: 1.1rem;
  border-radius: 0.5rem;
  background-color: var(--brand-yellow);
}

.lancet__money > img {
  position: absolute;
  right: 0;
}

.lancet__formandwhy {
  display: flex;
  padding: 2rem var(--body-side-paddings);
  height: 120vh;
}

.lancet__why {
  width: 50%;
  height: 100%;
}

.lancet__why-cards {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1.5rem;
  grid-gap: 2rem;
  padding: 2rem;
}

.lancet__why-heading {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.lancet__formcontainer {
  width: 50%;
  height: 100%;
  padding: 2rem;
  position: relative;
}

.lancet__form-heading {
  font-size: 1.8rem;
  font-weight: 600;
}

.tc {
  display: inline-block;
  margin-top: 1rem;
  font-size: 0.8rem;
}

.tc-input {
  display: flex;
  align-items: center;
}

.lancet__offer {
  width: 100%;
  height: 230px;
  position: relative;
}

.lancet__offer > img {
  width: 100%;
  position: relative;
}

.lancet__form {
  width: 90%;
  padding: 3rem;
  background-color: #fff;
  position: absolute;
  top: -15%;
  border-radius: 0.5rem;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
}

.lancet__offer-detail {
  position: absolute;
  top: 30%;
  left: 2.5rem;
  color: #fff;
}

.lancet__offer-heading {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}

.lancet__offer-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 1rem;
}

.lancet__offer-card {
  position: relative;
  width: 90px;
  height: 90px;
}

.lancet__offer-card:nth-child(odd) {
  margin-top: 1rem;
}

.lancet__offer-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: 800;
  color: #000;
}

.lancet__detail-heading {
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}

.lancet__detail-cards {
  padding: 2rem var(--body-side-paddings);
  display: flex;
  justify-content: space-between;
}

.lancet__detail-card {
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1629218593/website_images/scholarshipdetailbg.png");
  background-position: center;
  background-size: cover;
  color: #fff;
  padding: 2rem 3.5rem;
  height: 280px;
  width: calc(100% / 4);
}

.lancet__detail-card > h1 {
  font-size: 1.5rem;
  font-weight: 600;
}

.lancet__faq {
  width: 100%;
  min-height: 80vh;
  background-color: #f2f2f2;
  padding-bottom: 3rem;
}

.lancet__faq-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.lancet__faq-icon {
  background-color: #f2f2f2;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-top: -3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lancet__faq-heading {
  text-align: center;
  margin-top: 2rem;
  font-size: 2rem;
}

.lancet__faq-container {
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
}

.lancet__faq-card {
  width: 100%;
  margin-bottom: 2rem;
}

.lancet__faq-question {
  display: flex;
  justify-content: space-between;
  background-color: #ddd;
  align-items: center;
  height: 55px;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.lancet__faq-question-container {
  height: 100%;
  display: flex;
}

.lancet__faq-question-text {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
}

.lancet__faq-qno {
  display: inline-block;
  background-color: var(--brand-yellow);
  width: 55px;
  height: 100%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.2rem;
}

.lancet__faq-answer {
  padding: 1rem 4rem;
  display: none;
}

.lancet__faq-answer.answer-active {
  display: block;
}

.lancet__faq-answertext {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}

.lancet__terms {
  width: 70%;
  margin: 2rem auto;
  text-align: center;
}

.lancet__terms > p {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: 500px) {
  .lancet__navbar-login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
  }

  .lancet__navbar-login > h2 {
    font-weight: 500;
    font-size: 0.9rem;
    margin-right: 2rem;
  }

  .lancet__navbar {
    padding: 1rem;
    flex-direction: column;
  }

  .lancet__navbar > a > img {
    width: 200px;
  }

  .lancet__hero {
    flex-direction: column;
    height: initial;
  }

  .lancet__hero-img {
    width: 100%;
    height: 50vh;
  }

  .lancet__details {
    width: 100%;
    height: 42vh;
    padding-right: 0;
    padding: 1rem;
    text-align: center;
  }

  .lancet__heading {
    font-size: 1.5rem;
  }

  .lancet__money {
    width: 100%;
  }

  .lancet__money > h2 {
    width: 90%;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: left;
    padding: 1rem;
    padding-right: 0;
  }

  .lancet__timings {
    margin-top: 1.5rem;
    font-size: 0.6rem;
  }

  .lancet__formandwhy {
    display: flex;
    flex-direction: column-reverse;
    padding: 1rem;
    height: initial;
  }

  .lancet__why {
    width: 100%;
  }

  .lancet__formcontainer {
    width: 100%;
    height: 830px;
    position: relative;
    padding: 0;
  }

  .lancet__form {
    width: 100%;
    padding: 1rem;
    top: -5%;
  }

  .lancet__form-heading {
    font-size: 1.5rem;
  }

  .lancet__offer > img {
    height: 75%;
  }

  .lancet__offer-detail {
    top: 20%;
    left: 0.5rem;
  }

  .lancet__offer-card {
    width: 55px;
    height: 55px;
  }

  .lancet__offer-heading {
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
  }

  .lancet__offer-number {
    font-size: 0.6rem;
  }

  .lancet__why-cards {
    padding: 0;
  }

  .lancet__detail-cards {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .lancet__detail-card {
    margin-bottom: 1rem;
    width: 80%;
    padding: 1rem;
  }

  .lancet__faq-container {
    width: 100%;
  }

  .lancet__faq-question-container {
    height: 100%;
    width: 90%;
    display: flex;
  }

  .lancet__faq-question-text {
    height: 100%;
    width: 90%;
  }

  .lancet__faq-question {
    height: 75px;
  }

  .lancet__faq-qno {
    width: 75px;
  }
  .lancet__faq-answer {
    padding: 1rem 2rem 1rem 4.5rem;
  }
  .lancet__terms {
    width: 100%;
    padding: 0 1rem;
  }
}
